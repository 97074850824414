import React, { useState } from 'react';
import styled from 'styled-components';
// import i18n from 'i18next';
import { useClickOutside } from 'hooks';
import { useTranslation } from 'react-i18next';

const arrowDown = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M1 1L6 6L11 1" stroke="#333333" strokeLinecap="round"/>
</svg>;

const arrowUp = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M11 6L6 1L1 6" stroke="#333333" strokeLinecap="round"/>
</svg>;

const Container = styled.div`
	width: 76px;
	position: relative;
	z-index: 1;

	label, div {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;

		&:hover {
		}
	}

	label {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		cursor: pointer;
	}

	> div {
		border-radius: 2px;
		border: 1px solid ${props => props.theme.default};
		background-color: white;
		padding: 5px;
		top: calc(100% + 2px);
		left: 0;
		right: 0;
		position: absolute;
		display: flex;
		flex-direction: column;
		gap: 5px;

		div {
			display: flex;
			align-items: center;
			gap: 5px;
			cursor: pointer;
		}
	}
`;

const FlagKG = () => (
	<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14 0H0V10H14V0Z" fill="#FF0000"/>
		<circle cx="7" cy="5" r="3.5" fill="#FF0000" stroke="#FAFF00"/>
		<circle cx="7" cy="5" r="2" fill="#FAFF00"/>
	</svg>
);

const FlagCN = () => (
	<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14 0H0V10H14V0Z" fill="#FF0000"/>
		<path d="M3 1L3.47214 2.52786H5L3.76393 3.47214L4.23607 5L3 4.05573L1.76393 5L2.23607 3.47214L1 2.52786H2.52786L3 1Z" fill="#FAFF00"/>
		<circle cx="6.25" cy="4.75" r="0.25" fill="#FAFF00"/>
		<circle cx="7.25" cy="3.75" r="0.25" fill="#FAFF00"/>
		<circle cx="7.25" cy="2.25" r="0.25" fill="#FAFF00"/>
		<circle cx="6.25" cy="1.25" r="0.25" fill="#FAFF00"/>
	</svg>
);

const FlagEN = () => (
	<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14 0H0V10H14V0Z" fill="#0156D6"/>
		<path d="M0 1.5V0H1.5L14 8.5V10H12.5L0 1.5Z" fill="#F5F5F5"/>
		<path d="M12.5 0H14V1.5L2 10H0V8.5L12.5 0Z" fill="#F5F5F5"/>
		<path d="M5.5 0H8.5V10H5.5V0Z" fill="#F5F5F5"/>
		<path d="M0 3.5H14V6.5H0V3.5Z" fill="#F5F5F5"/>
		<path d="M0 1V0H1L14 9V10H13L0 1Z" fill="#FF0000"/>
		<path d="M14 1V0H13L0 9V10H1L14 1Z" fill="#FF0000"/>
		<rect x="6" width="2" height="10" fill="#FF0000"/>
		<rect y="4" width="14" height="2" fill="#FF0000"/>
	</svg>
);

const Lang = () => {
	const { i18n } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));
	const lang = i18n.language;

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		// window.location.reload();
		setOpen(false);
	};

	const onToggle = () => setOpen(prev => !prev);

	return (
		<Container ref={ref} id='lang_switcher'>
			<label onClick={onToggle}>
				{lang === 'kg' && <><FlagKG /> КЫР</>}
				{lang === 'cn' && <><FlagCN /> CN</>}
				{lang === 'en' && <><FlagEN /> ENG</>}

				{isOpen ? arrowUp : arrowDown}
			</label>
			
			{isOpen && <div>
				<div onClick={changeLanguage.bind(this, 'kg')}>
					<FlagKG />
					КЫР
				</div>

				<div onClick={changeLanguage.bind(this, 'cn')}>
					<FlagCN />
					CN
				</div>

				<div onClick={changeLanguage.bind(this, 'en')}>
					<FlagEN />
					ENG
				</div>
			</div>}
		</Container>
	);
};

export default Lang;
