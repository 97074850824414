import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	* {
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		color: #333;
		font-weight: bold;
	}
`;

const Dot = styled.span`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	font-weight: bold;
	color: inherit;
	font-weight: bold;
	display: inline-block;
	margin: 0 5px;
`;

const HotelSubways = ({ objects, className = '' }) => {
	return objects.length > 0 ? (
		<Container className={className}>
			<b>Subway</b>
			{objects.map((i, key) => (
				<React.Fragment key={key}>
					<span key={key} style={i?.ObjectSubType?.AddInfo ? {color: i?.ObjectSubType?.AddInfo} : {}} title={i?.ObjectSubType?.Name}>
						<Dot>•</Dot>
						{i.Name} - {i.Distance.toString().replace('.', ',')} km
					</span>
				</React.Fragment>
			))}
		</Container>
	) : null;
};

export default HotelSubways;