import React, { useState } from 'react';
import styled from 'styled-components';
import { Burger, Menu, Title } from './';
import { useLocation } from 'react-router-dom';
import { useEffectExceptOnMount } from 'hooks';

const Container = styled.div`
	background-color: white;
	height: 50px;
	width: 100vw;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	padding: 0 20px;

	&.archive {
		background-color: ${props => props.theme.orange};
	}

	> * {
		flex-shrink: 0;
	}

	&:after {
		content: '';
		position: absolute;
		background: linear-gradient(180deg, rgba(0, 70, 133, 0.2) 0%, rgba(0, 70, 133, 0) 100%);
		height: 10px;
		width: 100vw;
		position: absolute;
		top: 100%;
		left: 0;
	}
`;

const Mobile = () => {
	const [ isOpen, setOpen ] = useState(false);
	const loc = useLocation();

	useEffectExceptOnMount(() => {
		setOpen(false);
	}, [loc?.pathname, loc?.key, loc?.hash]);

	// console.log(JSON.stringify(loc, null, 4));

	const toggle = () => setOpen(prev => !prev);

	return (
		<>
			<Container>
				<Title />
				<Burger toggle={toggle} />
			</Container>

			<Menu isOpen={isOpen} toggle={toggle} />
		</>
	);
};

export default Mobile;