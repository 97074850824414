import React from 'react';
import styled from 'styled-components';
import { Desktop, Mobile } from './';
import { useDevice } from 'hooks';

const Container = styled.div`
	width: 100vw;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 10;
`;

const Header = () => {
	const { isDesktop } = useDevice();

	return (
		<Container>
			{isDesktop
				? <Desktop />
				: <Mobile />
			}
		</Container>
	);
};

export default Header;