import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'styles/reset.css';
import 'utils/i18n';
import { ErrorProvider } from 'stores';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Suspense fallback={null}>
			<ErrorProvider>
				<App />
			</ErrorProvider>
		</Suspense>
	</BrowserRouter>
);
