import { useTranslation } from 'react-i18next';

const useMenuItems = () => {
	const { t } = useTranslation();

	return [
		{ link: '/', label: t('Башкы бет') },
		{ link: '/hotels', label: t('Мейманканалар') },
		{ link: '/contacts', label: t('Байланышуу') },
	];
};

export default useMenuItems;