import { useCallback, useState } from 'react';

const useBoolToggle = (initialValue = false) => {
	const [ value, setValue ] = useState(initialValue);

	const onToggle = useCallback(() => setValue(prev => !prev), []);
	const onSet = useCallback(i => setValue(Boolean(i)), []);

	return {
		value,
		onSet,
		onToggle
	};
};

export default useBoolToggle;