import React from 'react';
import styled from 'styled-components';
import {
	Lang,
	MainMenu,
	Title,
} from './';

const Container = styled.div`
	background-color: white;
	height: 50px;
	width: 100vw;
	position: relative;
	display: flex;
	align-items: center;
	gap: 20px;
	padding: 0 40px;

	> * {
		flex-shrink: 0;
	}

	&:after {
		content: '';
		position: absolute;
		background: linear-gradient(180deg, rgba(0, 70, 133, 0.2) 0%, rgba(0, 70, 133, 0) 100%);
		height: 10px;
		width: 100vw;
		position: absolute;
		top: 100%;
		left: 0;
	}
`;

const Desktop = () => {
	return (
		<>
			<Container>
				<Title />
				<div className='filler' />
				<Lang />
				<MainMenu />
			</Container>
		</>
	);
};

export default Desktop;