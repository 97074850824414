/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Container = styled.div`
	background-color: ${props => props.theme.default};
	display: flex;
	flex-direction: column;
	align-items: center;

	> * {
		width: calc(100vw - 80px);

		@media (min-width: 1280px) {
			max-width: 1240px;
		}
	}
`;

const Menu = styled.div`
	height: 80px;
	align-items: center;
	justify-content: center;
	gap: 20px;
	border-bottom: 1px solid white;
	display: none;

	@media (min-width: 1280px) {
		display: flex;
	}

	a {
		color: white;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 400;
	}
`;

const Copyright = styled.div`
	color: white;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${props => props.theme.pt};
`;

const year = new Date().getFullYear();

const Footer = () => {
	const { t } = useTranslation();

	return (
		<Container>
			<Menu>
				<Link to='/'>{t('Башкы бет')}</Link>
				<Link to='/hotels'>{t('Мейманканалар')}</Link>
				<Link to='/contacts'>{t('Байланышуу')}</Link>
			</Menu>
			
			<Copyright>
				© ACS.KG. {t('Бардык укуктар корголгон')}. {year}
			</Copyright>
		</Container>
	);
};

export default Footer;