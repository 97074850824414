import { useEffect, useRef } from 'react';

const useClickOutside = (callback = () => null) => {
	const mainRef = useRef(document);
	const ref = useRef(null);

	const handleClick = e => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		mainRef.current && mainRef.current.addEventListener('mousedown', handleClick, true);
		// window.addEventListener('scroll', handleClick, true);
		
		return () => {
			mainRef.current && mainRef.current.removeEventListener('mousedown', handleClick, true);
			// window.removeEventListener('scroll', handleClick, true);
		};
	}, [mainRef.current]);

	return {
		mainRef,
		ref,
	};
};

export default useClickOutside;