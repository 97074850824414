import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		left: -100px;
	}
	
	::-webkit-scrollbar-track {
		background: white;
		border-radius: 2px;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: ${props => props.theme.default};
		border-radius: 2px;
		width: 10px;
	}

	* {
		scrollbar-color: ${props => props.theme.default} white;
		scrollbar-width: thin;
	}

	body {
		background-color: white;
	}

	svg {
		user-select: none !important;
	}

	html {
		overflow-y: scroll;
		overflow-x: hidden;

		body {
			background: white;
			font-family: 'Rubik', sans-serif;
			position: relative;
			max-width: 100vw;
			width: 100%;
			height: 100vh;

			div#root {
				min-height: 100vh;
				height: auto;
				display: flex;
				flex-direction: column;
				align-items: stretch;

				.desktop {
					@media (max-width: 1279px) {
						display: none !important;
					}
				}
			}

			a {
				font-family: ${props => props.theme.rubik};
				text-decoration: none;
			}

			* {
				font-size: 16px;
				line-height: 21px !important;
				font-weight: 400;
				color: #333;
			}

			.portal .dates_input__calendar_container {
				position: fixed;
			}

			svg {
				flex-shrink: 0;
			}

			img {
				object-fit: contain;
			}

			* {
				box-sizing: border-box !important;
				font-family: 'Rubik', sans-serif;
				transition: 
					color .2s ease-in-out, 
					background-color .2s ease-in-out,
					opacity .2s ease-in-out,
					visibility .2s ease-in-out;
			}

			h1 {
				color: ${props => props.theme.default};
				font-size: 24px;
				font-weight: 500;
				line-height: 28.44px;
			}

			h2 {
				color: ${props => props.theme.default};
				font-size: 20px;
				font-weight: 500;
				line-height: 24px;
			}

			h3 {
				color: ${props => props.theme.default};
				font-size: 16px;
				font-weight: 500;
				line-height: 19px;
			}

			.filler {
				display: flex;
				flex-grow: 1;
			}

			p {
				font-family: ${props => props.theme.pt};
				font-size: 18px;
				font-weight: 400;
				line-height: 23.31px;

				b {
					font-family: inherit;
					font-size: inherit;
					line-height: inherit;
					font-weight: 700;
					color: ${props => props.theme.default};
				}
			}

			.button {
				cursor: pointer;
				height: 30px;
				padding: 0 20px;
				align-items: center;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				white-space: nowrap;
				font-family: Rubik;
				font-size: 14px !important;
				font-weight: 400 !important;
				text-transform: uppercase;

				&.gradient {
					background: linear-gradient(180deg, #FD865E 0%, #FF0000 100%);
					color: white;
				}

				&.white {
					background-color: white;
					border: 2px solid ${props => props.theme.default};
					color: ${props => props.theme.default};

					&:hover {
						background-color: ${props => props.theme.default};
						color: white;
					}
				}
			}
		}
	}
`;

export default GlobalStyles;