import React from 'react';
import styled from 'styled-components';
import { useMenuItems } from 'hooks';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	a {
		font-weight: 400;
		font-size: 16px;
		text-transform: uppercase;

		&[aria-current] {
			color: ${props => props.theme.default};
		}
	}
`;

const MainMenu = () => {
	const items = useMenuItems();

	return (
		<Container>
			{items.map((i, key) =>
				<NavLink key={key} to={i.link}>
					{i.label}
				</NavLink>
			)}
		</Container>
	);
};

export default MainMenu;