import { useEffect, useState } from 'react';
import { useErrorStore } from 'stores';
import axios, { AxiosError } from 'axios';
import { useEffectExceptOnMount } from './';

const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";

const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json; charset=UTF-8',
	'Bora-Alien': 2,
	'Bora-Formatted-Output': 1,
	env: 12,
};

let timer = null;
let intervalTimer = null;

const useRequest = (
	initialRequest = false,
	{
		parser = false,
		manual = false,
		onBefore = false,
		onSuccess = false,
		onError = false,
		onFinally = false,
		loadingDelay = false,
		refreshDeps = [],
		debounceWait = false,
		headers = {},
		usePagination = false,
		defaultData = false,
		responseType = 'json',
		baseURL = '/rapi99',
		rawParser = false,
		interval = false,
	} = {}
) => {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ data, setData ] = useState(defaultData);
	const [ page, setPage ] = useState(1);
	const [ lastTime, setLastTime ] = useState(0);
	const { setFatalError, setError: setCustomError } = useErrorStore();
	
	const fetchRequest = async ({ page = 1, request: rawRequest = initialRequest, withLoader = true }) => {
		const request = JSON.parse(JSON.stringify(rawRequest));

		if (window?.location?.hostname === 'localhost' || /^local-/.test(window?.location?.hostname)) {
			const d = new Date();
			const dateString = `${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()}:${d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()}`;
			const root = Object.keys(request)[0];
			if (request[root]?.Action?.Name !== undefined) {
				console.log(`%cFetch: ${Object.keys(request)[0]}.${request[root]?.Action?.Name}     ${dateString}`, 'color: #ff0000; font-weight: 600;');
			} else if (Object.keys(request[root]).length === 1) {
				console.log(`%cFetch: ${Object.keys(request)[0]}.${Object.keys(request[root])[0]}     ${dateString}`, 'color: #ff0000; font-weight: 600;');
			} else {
				console.log(`%cFetch: ${Object.keys(request)[0]}     ${dateString}`, 'color: #ff0000; font-weight: 600;');
			}
		}

		const userData = {
			UserType: 'BUYER',
			BuyerId: 'RO1BAND',
			UserId: 'WWW',
			Password: '84765',
			Language: 'en',
		};

		const instance = axios.create({
			baseURL,
			headers: {
				...defaultHeaders,
				...headers,
			},
			responseType,
			maxContentLength: Infinity,
			maxBodyLength: Infinity,
		});

		const root = Object.keys(request)[0];

		const req = {
			[root]: {
				...userData,
				...request[root],
			},
		};

		const preparedRequest = typeof usePagination?.addMixin !== 'function'
			? req
			: usePagination.addMixin({ request: req, page, limit: usePagination.limit });

		withLoader && setLoading(true);
		setError(false);
		// setData(defaultData);
		typeof onBefore === 'function' && onBefore();

		const data = await instance.post('', JSON.stringify(preparedRequest))
			.then(async resp => {
				typeof rawParser === 'function' && rawParser(resp);

				const {
					config,
					request,
					data: rawData,
				} = resp;

				const responseData = isJsonBlob(rawData) ? await (rawData)?.text() : rawData || {};
				const data = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData;

				const root = Object.keys(data)[0];

				if (data?.Error !== undefined) {
					throw new AxiosError(
						data?.Error,
						{
							config,
							request,
							response: data,
						}
					);
				}

				if (data[root]?.Error !== undefined) {
					throw new AxiosError(
						data[root]?.Error,
						{
							config,
							request,
							response: data,
						}
					);
				}

				return data;
			})
			.then(data => {
				const root = Object.keys(data)[0];
				const result = !root ? data : data[root];

				if (typeof parser === 'function') {
					setData(parser(result));
				} else {
					setData(result);
				}
				usePagination !== false && setPage(page);
				typeof onSuccess === 'function' && onSuccess(result);
				return result;
			})
			.catch(e => {
				console.log(e, preparedRequest);
				console.log(JSON.stringify(preparedRequest, null, 4));
				if (e?.code === 'ERR_CANCELED') return false;
				const status = e?.response?.status;

				if (status === 502) {
					setFatalError('Сервис временно недоступен.');
				} else if (status === 404) {
					setFatalError('Сервис временно недоступен.');
				} else if (status === 504) {
					setFatalError('Превышено время ожидания ответа сервиса.');
				} else if (status === 500) {
					setFatalError('Фатальная ошибка сервиса.');
				} else if (status) {
					setFatalError(`Ошибка ${e?.response?.status}: ${e?.message}`);
				} else {
					setCustomError(e.message);
					// const requestStyle = 'color: #00aa00';
					// const responseStyle = 'color: #ff0000';
					// console.log('%cRequest:', requestStyle, JSON.stringify(request));
					// console.log('%cResponse: %s', responseStyle, JSON.stringify(error));
				}

				typeof onError === 'function' && onError(e);
				return false;
			})
			.finally(() => {
				withLoader && setLoading(false);
				setLastTime(new Date().getTime());
				typeof onFinally === 'function' && onFinally();
			});

		return data;
	};

	useEffect(() => {
		if (manual || initialRequest === false) return;

		if (!isNaN(Number(loadingDelay))) {
			timer = setTimeout(() => fetchRequest({ page }), Number(loadingDelay));
			return;
		}

		fetchRequest({ page });
	}, []);

	useEffectExceptOnMount(() => {
		if (manual || initialRequest === false) return;

		if (Number(debounceWait) > 0) {
			clearTimeout(timer);
			timer = setTimeout(() => fetchRequest(1), Number(debounceWait));
			return;
		}

		fetchRequest({ page: 1 });
	}, [...refreshDeps, manual]);

	useEffect(() => {
		clearInterval(intervalTimer);
		if (!(interval > 0) || manual || initialRequest === false || loading) return;
		intervalTimer = setInterval(() => fetchRequest({ withLoader: false }), interval);
	}, [...refreshDeps, manual, initialRequest, interval, loading]);

	// console.log({manual});

	// useEffectExceptOnMount(() => {
	// 	if (usePagination === false) return;
	// 	if (initialRequest === false) return;
	// 	if (loading) return;

	// 	if (!isNaN(Number(debounceWait))) {
	// 		clearTimeout(timer);
	// 		timer = setTimeout(() => fetch({ page }), Number(debounceWait));
	// 		return;
	// 	}

	// 	fetch({ page });
	// }, [page]);

	const run = async (page = 1) => {
		if (loading) return;
		// setPage(Number(page));
		if (isNaN(Number(page))) page = 1;
		const data = await fetchRequest({ page });

		if (!data) return false;

		if (typeof parser === 'function') {
			return parser(data);
		}

		return data;
	};

	const runRequest = async (request) => {
		if (loading || !request) return;
		const data = await fetchRequest({ request });
		return data;
	};

	const pagination = usePagination === false
		? false
		: {
			limit: usePagination.limit,
			itemsLength: usePagination.itemsLength(data),
			pagesLength: Math.ceil(usePagination.itemsLength(data) / usePagination.limit),
			page,
			setPage: page => {
				run(Number(page));
			},
		};

	const reset = (data = defaultData) => setData(data);

	return {
		loading,
		error,
		data,
		run,
		cancel: () => false,
		pagination,
		lastTime,
		reset,
		runRequest,
		setData,
	};
};

export default useRequest;