/* eslint-disable no-unused-vars */
import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const About = lazy(() => import('pages/About'));
const NotFound = lazy(() => import('pages/NotFound'));
const Countries = lazy(() => import('pages/Countries'));
const Country = lazy(() => import('pages/Country'));
const City = lazy(() => import('pages/City'));
const Hotel = lazy(() => import('pages/Hotel'));
const Contacts = lazy(() => import('pages/Contacts'));

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const Router = () => {
	const { t } = useTranslation();

	return (
		<Container>
			<Routes>
				<Route path='/' element={<About />} />
				<Route path='/hotels' element={<Countries />} />
				<Route path='/country/:id' element={<Country />} />
				<Route path='/city/:cityCode' element={<City />} />
				<Route path='/hotel/:id' element={<Hotel />} />
				<Route path='/contacts' element={<Contacts />} />

				<Route path='/not_found' element={<NotFound />} />
				<Route path='*' element={<Navigate replace to='not_found' />} />
			</Routes>
		</Container>
	);
};

export default Router;