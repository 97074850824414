import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Lang, MainMenu } from './';

const Container = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	visibility: hidden;

	&.isOpen {
		visibility: visible;
	}

	@media (min-width: 660px) {
		width: 320px;
	}

	> div {
		height: 100vh;
		width: 100vw;
		background-color: white;
		position: fixed;
		top: 0;
		right: -100%;
		padding: 20px 20px 0 20px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 20px;
		transition: right ease-in-out .1s;
		overflow-y: auto;

		&.isOpen {
			right: 0;
		}

		@media (min-width: 660px) {
			width: 320px;
		}
	}
`;

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: #004685CC;
	opacity: 0;
	visibility: hidden;

	&.isOpen {
		opacity: 1;
		visibility: visible;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;

	> * {
		flex-shrink: 0;
	}
`;

const Line = styled.div`
	height: 0 !important;
	border-top: 1px solid ${props => props.theme.lightGray};
`;

const CloseIcon = () => (
	<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.72939 3.70588C4.11885 3.31603 4.75029 3.31603 5.13975 3.70588L26.2951 24.8824C26.6845 25.2722 26.6845 25.9043 26.2951 26.2941C25.9056 26.684 25.2742 26.684 24.8847 26.2941L3.72939 5.11765C3.33993 4.7278 3.33993 4.09573 3.72939 3.70588Z" fill="#333"/>
		<path d="M26.2704 3.70589C26.6599 4.09574 26.6599 4.72781 26.2704 5.11766L5.1151 26.2941C4.72564 26.684 4.09421 26.684 3.70475 26.2941C3.31529 25.9043 3.31529 25.2722 3.70475 24.8824L24.8601 3.70589C25.2495 3.31604 25.881 3.31604 26.2704 3.70589Z" fill="#333"/>
	</svg>
);

const Menu = ({ isOpen, toggle }) => {
	return (
		<>
			<Overlay onClick={toggle} className={classnames({ isOpen })} />

			<Container className={classnames({ isOpen })}>
				<div className={classnames({ isOpen })}>
					<Row>
						<Lang />

						<div onClick={toggle}>
							<CloseIcon />
						</div>
					</Row>

					<Line />

					<MainMenu />
				</div>
			</Container>
		</>
	);
};

export default Menu;