import React from 'react';
// import i18n from 'i18next';
import { ThemeProvider } from 'styled-components';
import { Footer, GlobalStyles, Router } from 'components';
import Header from 'features/Header';

const theme = {
	default: '#FF0000',
	secondary: '#FF9900',
	darkBlue: '#004685',
	lightBlue: '#0270CB',
	lightGray: '#D5E3F0',
	orange: '#FF6B00',
	inputBorderColor: '#80A2C2',
	placeholderColor: '#80A2C2',
	pt: "'PT Sans', sans-serif !important",
	rubik: "'Rubik', sans-serif",
};

function App() {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<Header />
			<Router />
			<Footer />
		</ThemeProvider>
	);
}

export default App;
