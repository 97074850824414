import { useEffect, useRef, useState } from 'react';

let timer = null;

const useGalleryLogic = (total, index, setIndex) => {
	const [ current, setCurrent ] = useState(false);
	const [ transition, setTransition ] = useState(false);
	const nextButtonRef = useRef(null);
	const prevButtonRef = useRef(null);
	const slideRef = useRef(null);

	useEffect(() => {
		if (current === false && index !== false) {
			setCurrent(index);
			clearTimeout(timer);

			timer = setTimeout(
				() => setTransition(true),
				200
			);
		} else if (current !== false && index === false) {
			clearTimeout(timer);

			timer = setTimeout(
				() => {
					setTransition(false);
					setCurrent(false);
				},
				200
			);
		} else {
			setCurrent(index);
		}
	}, [index]);

	const onClickPrev = () => setIndex(prev => prev > 0 ? (prev - 1) : 0);
	
	const onClickNext = () => setIndex(prev => prev < (total - 1) ? prev + 1 : total);
    
	const onClose = () => setIndex(false);

	const onEsc = e => index !== false && e.keyCode === 27 && onClose();

	const onClickOutside = e => {
		const {target} = e;
		if (!nextButtonRef.contains(target) && !prevButtonRef.contains(target) && !slideRef.contains(target)) {
			onClose();
		}
	};

	return {
		current,
		transition,
		onClickNext,
		onClickPrev,
		onClose,
		onEsc,
		onClickOutside,
		nextButtonRef,
		prevButtonRef,
		slideRef,
	};
};

export default useGalleryLogic;