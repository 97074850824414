import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
	/* border: 1px solid red; */
	display: flex;
	align-items: center;
`;

const Title = () => {
	return (
		<Container to='/'>
			<img src='/images/logo.jpg' height='46' />
		</Container>
	);
};

export default Title;